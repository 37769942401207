// index.ts
// 通过vue-router插件实现模板路由配置
import { createRouter, createWebHistory,useRouter } from "vue-router";
import { constantRoute } from "./routers";
// import { useUserStore } from '../store/index'
const routers = useRouter()
// 白名单
const white = ['/'];
// 创建路由器
const router = createRouter({
  // 路由模式hash
  history: createWebHistory(''),
  routes: constantRoute,
  // 滚动行为
  scrollBehavior() { 
    return {
      left: 0,
      top: 0,
    };
  },
});
router.beforeEach((to, form, next) => {
    const userstore = localStorage.getItem('token')  
    if (!userstore) {
     if (white.includes(to.path)) {
        next();
        return
     }   
    } else {
        next();
    }
});
export default router;
