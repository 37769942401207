import layout from "../views/mine/MineList.vue";
export const constantRoute:any = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/AboutProject.vue"),
  },
  {
    path: "/Seller",
    name: "Seller",
    component: () => import("../views/SellerView.vue"),
  },
  {
    path: "/Manager",
    name: "Manager",
    component: () => import("../views/ManagerView.vue"),
  },
  {
    path: "/Incident",
    name: "Incident",
    component: () => import("../views/IncidentView.vue"),
  },
  {
    path: "/mine",
    name: "Mine",
    component: layout,
    children: [
      {
        path: "/mine/minevideo",
        name: "MineVido",
        component: () => import("../views/mine/MineVideo.vue"),
      },
    ],
  },
];
