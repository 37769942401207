import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import piniaPluginPersist from 'pinia-plugin-persist'
import TDesign from 'tdesign-vue-next';
import 'tdesign-vue-next/es/style/index.css';

// 引入组件库的少量全局样式变量
const pinia = createPinia()
pinia.use(piniaPluginPersist)
createApp(App).use(pinia).use(TDesign).use(router).mount('#app')
