import request from "../utils/request";
// 首页
export const homeList = () => {
    return request({
      method:"get",
      url:"index/index/home_list",
    })
  };
// 卖家
export const sellList = () => {
    return request({
      method:"get",
      url:"web/index/sell_list",
    })
  };
// 市场经理
export const marketList = () => {
    return request({
      method:"get",
      url:"web/index/market_list",
    })
  };
//   事件
export const eventList = () => {
    return request({
      method:"get",
      url:"web/index/event_list",
    })
  };
//   关于
export const projectList = () => {
    return request({
      method:"get",
      url:"web/index/project_list",
    })
  };
//页脚
export const footerList = () => {
    return request({
      method:"get",
      url:"web/index/footer_list",
    })
  };
//公用
export const webConf = () => {
    return request({
      method:"get",
      url:"index/index/web_conf",
    })
  };
// 帮助
export const helpList = () => {
    return request({
      method:"get",
      url:"web/index/help_list",
    })
  };
// 视频列表
export const videoList = () =>{
    return request({
        method:"get",
        url:"web/index/video_list",
      })
}
// 注册
export const verificationCode = (data:any) =>{
    return request({
        method:"post",
        url:"system/sms/send_sms",
        data
      })
}

// 登录
export const LogIn = (data:any) =>{
    return request({
        method:"post",
        url:"member/login/code_login",
        data
    })
}